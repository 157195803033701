import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import axiosInstance from "../http/axios";
import LineChart from "../chart/LineChart";

const GetForecast = ({lat, lng}) => {

    const { t } = useTranslation();
    const [isSubmitting, setSubmitting ] = useState(false);
    const [index, setIndex] = useState([]);
    const [data, setData ] = useState([]);
    const [currentOption, setCurrentOption] = useState(null);
    const [horizon, setHorizon] = useState('1d');
    const [error, setError ] = useState(null);
    const [reload, setReload ] = useState(0);
    const [legend, setLegend ] = useState('');
    const [unity, setUnity] = useState('');

    const options = ['humidity','precipitation', 'precipitation_acc','temperature'];
    const codes = ['tcwv', 'mtpr', 'mtpa', 't2m'];


    useEffect(() => {
        if(horizon && currentOption && reload) {
            const i = options.indexOf(currentOption);
            getData(horizon, currentOption,i);

        }
    }, [horizon, currentOption, reload])

    const getFormattedDate = (date, the, at, horizon) => {
        const now = new Date(date);

        // Get day number (1-31)
        const day = now.getDate();
        const year = now.getFullYear();
        const month = now.getMonth() + 1;

        // Get time in HH:MM:SS format
        const hours = String(now.getHours()).padStart(2, '0');
        const time = `${hours} h`;


        return { date: `${day}-${month}-${year}`, time:  `${the} ${day} ${at} ${time}` };


    };

    const getData = (horizon, option, index) => {
        setCurrentOption(option);
        setSubmitting(true);
        setError(null);
        axiosInstance.get('/api/forecast/' + lat + '/' + lng + '/' + option +'/' + horizon).then((response) => {
            const the = t('the');
            const at = t('at');
            setIndex(response.data.index.map(date=> getFormattedDate(date,the,at, horizon)));
            setData(response.data.data.map(e => e[0]));
            setSubmitting(false);
            let _unity = response?.data?.meta_data?.fields[codes[index]]?.unity ?? '%';
            setUnity(_unity);
            setLegend(t(option) + '('+  _unity + ')');

        }).catch((error) => {
            setSubmitting(false);
            if (error?.response?.status) {
                setError(error?.response?.data.message );
            }
        })
    }

    return (
        <>{ lat && lng && (
            <div>
                { currentOption ?
                        <div className="forecast-title-container">
                            <h1>{t(currentOption)}</h1>
                            <select value={horizon} onChange={e => setHorizon(e.target.value)}>
                                <option value="1d">{t('one_day')}</option>
                                <option value="7d">{t('seven_day')}</option>
                                <option value="15d">{t('15_day')}</option>
                            </select>
                        </div>
                        :
                        <h1>{t('forecast')}</h1>
                }
                { error ? <div className="alert alert-danger forecast-margin-alert" role="aler">{t('forecast_api_error')} : {error}</div> : <></> }
                <div className="container-btn-forecast">{options.map((option) => {
                    return (
                        <button
                            key={option}
                            className={`btn btn-margin-forecast btn-forecast ${option===currentOption?'btn-success':'btn-dark'}`}
                            onClick={e=> { setCurrentOption(option);setReload(reload+1);} }
                            disabled={isSubmitting}>
                                {t(option)}
                        </button>)
                })}</div>
                { currentOption ?
                    <LineChart label={index} data={data} title={t(currentOption)}  legend={legend} horizon={horizon} unity={unity}></LineChart>:
                    <div>{t('choose_option')}</div>}

            </div>)}
        </>
    );

}
export default GetForecast;