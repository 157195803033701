import {useLocation, useNavigate} from "react-router-dom";
import {useCallback, useDeferredValue, useEffect, useState} from "react";
import {useSelector} from "react-redux";

const HeaderPicture = () => {

    const application_type = process.env.REACT_APP_TYPE;

    const site_b2b_url = "https://www.toolsforfarming.com";
    const site_b2c_url = "https://www.mygoodtools.eu";

    const location = useLocation();

    const navigate = useNavigate();

    const [isOrigin , setIsOrigin] = useState(true );
    const [url, setUrl ] = useState(null);

    const deferredUrl = useDeferredValue(url);

    const [className , setClassName ] = useState(null);
    const deferredClassName = useDeferredValue(className)
    const lang = useSelector((state) => state.language);

    useEffect(() => {
        if (location.pathname.match(/(^(\/$)|(\/[a-zA-Z]{2}(\/login))|(\/[a-zA-Z]{2})$)/))  {
            setIsOrigin(true);
        } else {
            setIsOrigin(false);
        }
    }, [location.pathname])


    useEffect(() => {
        if(isOrigin && application_type === 'b2b') {
            setUrl('/header_intro_b2b.png')
        }
        if(isOrigin && application_type === 'b2c') {
            setUrl('/header_intro_b2c.png');
        }
        if(!isOrigin && application_type === 'b2b') {
            setUrl('/header_site_b2b.png');
        }
        if(!isOrigin && application_type === 'b2c') {
            setUrl('/header_site_b2c.png');
        }
        if( isOrigin ) {
            setClassName('logo-header');
        }
        if( !isOrigin ) {
            setClassName('logo-header-site');
        }
    }, [application_type, isOrigin])

    const goHome = useCallback(() => {
        if(isOrigin && application_type === 'b2b') {
            window.location.href = site_b2b_url;
        }else if ( isOrigin && application_type === 'b2c') {
            window.location.href = site_b2c_url;
        } else if (!isOrigin ) {
            navigate('/' + lang);
        }
    }, [isOrigin, application_type, lang, location.pathname ])

    return (
        <>
            {deferredUrl && deferredClassName && (
                <a className="navbar-brand" href="#" onClick={e => goHome()}>
                    <img className={className} src={url} alt="Farming Solution"/>
                </a>
                )}
        </>
    )
}
export default HeaderPicture;