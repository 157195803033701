import React, {useEffect} from "react";
import LoginComponent from "../components/LoginComponent";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import { useSelector} from "react-redux";
import QuestionForm from "../components/QuestionForm";
import {Helmet} from "react-helmet-async";

const Login = () => {

    const application_type = process.env.REACT_APP_TYPE;

    const { t } = useTranslation();

    const navigate = useNavigate();

    const lang = useSelector((state) => state.language);
    const goSubscribe = (event) => {
        navigate('/' + lang + '/subscribe');
    }

    const replacePlaceholders= (template, values) => {
        return template.replace(/{{\s*(\w+)\s*}}/g, (match, p1) => {
            return values[p1] || match;
        });
    }



    return (
        <>
            <Helmet>
                <title>{ application_type === 'b2b' ? 'Farming Weather' : 'Precision Weather Forecast'}</title>
            </Helmet>

            <div className="page left">
                <img className="logo-left" src={ `${application_type === 'b2b' ? '/header_site_b2b.png': (application_type === 'b2c' ? '/header_site_b2c.png' : '/farming_solution.jpg')}`} />
            </div>
            <div className="page right">
                <LoginComponent></LoginComponent>
                <div><a href={`/${lang}/forgot-password`}>{t('password_forgotten')}</a></div>
                <div><button className="btn btn-success form-control" onClick={e => goSubscribe(e)}>{t('test_freely')}</button> </div>
                <div className="message-login">
                    <h4>{t('write_message')}</h4>
                    <QuestionForm isCaptcha={true}></QuestionForm>
                </div>
            </div>
        </>
    );
}
export default Login;